import React, { useState } from 'react';
import { Stack, Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import useStyles from './PdfViewer.styles';
import AddToBenchmarkDialog from '../../AddToBenchmarkDialog/AddToBenchmarkDialog';

export default function PdfViewer({ url, fileItem }) {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const highlight = searchParams.get('fp_highlight');
  const pageNumber = searchParams.get('fp_page');

  const getKeywords = () => {
    if (!highlight) {
      return [];
    }

    return highlight.split(' ');
  };

  const transform = (slot) => ({
    ...slot,
    ShowPropertiesMenuItem: () => null,
    Open: () => null,
    OpenMenuItem: () => null,
  });

  // eslint-disable-next-line no-use-before-define
  const renderToolbar = (Toolbar) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      getFilePlugin: {
        fileNameGenerator: () => fileItem.name,
      },
      searchPlugin: {
        keyword: getKeywords(),
      },
    },
    renderToolbar,
  });

  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

  const handleAddToBenchmark = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 64px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack
        style={{ padding: 8, fontWeight: 500 }}
        direction="row"
        spacing={2}
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        {fileItem.name}
        {fileItem.type === 'file' && (
          <Button onClick={handleAddToBenchmark}>
            Add to benchmark
          </Button>
        )}
      </Stack>
      <div className={classes.viewer} style={{ minHeight: 0 }}>
        <Viewer
          fileUrl={url}
          initialPage={pageNumber}
          plugins={[defaultLayoutPluginInstance]}
        />
      </div>
      {isDialogOpen && (
        <AddToBenchmarkDialog
          open
          onClose={handleCloseDialog}
          fileItem={fileItem}
        />
      )}

    </div>
  );
}
