import React, { useState } from 'react';

import {
  Button, Stepper, Step, StepLabel, StepContent,
  Stack, Divider,
} from '@mui/material';

import Layout from 'components/Layout';

import ClassificationTestBenchDialog from './ClassificationTestBenchDialog';

import getConfigsFromSheet from './utils/getConfigsFromSheet';
import getDocsFromSheet from './utils/getDocsFromSheet';

import useDownloadTemplateWithBenchmarkFiles from './hooks/useDownloadTemplateWithBenchmarkFiles';

const ExcelJS = require('exceljs');

const BenchmarkPage = () => {
  const [requestsData, setRequestsData] = useState(null);
  const downloadTemplateWithBenchmarkFiles = useDownloadTemplateWithBenchmarkFiles();

  const handleFilesInput = async (e) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const arrayBuffer = event.target.result;
      const buffer = new Uint8Array(arrayBuffer);

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);

      const configs = getConfigsFromSheet(workbook.getWorksheet(2));

      const firstRow = workbook.getWorksheet(1).getRow(1);

      workbook.getWorksheet(1).spliceColumns(3, 1000);

      firstRow.getCell(3).value = 'File name';
      firstRow.getCell(3).font = { bold: true };

      configs.forEach((config, index) => {
        firstRow.getCell(4 + index * 2).value = `Config ${config.id}`;
        firstRow.getCell(4 + index * 2).font = { bold: true };
      });

      const docs = getDocsFromSheet(workbook.getWorksheet(1));

      setRequestsData({ docs, configs, workbook });
    };

    reader.readAsArrayBuffer(e.target.files[0]);

    e.target.value = '';
  };

  return (
    <Layout
      app="benchmark"
    >
      <Stepper orientation="vertical">
        <Step active>
          <StepLabel>Download a template</StepLabel>
          <StepContent>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                component="a"
                href={`${process.env.PUBLIC_URL}/Test bench classification.xlsx`}
              >
                Example
              </Button>
              <Divider orientation="vertical" flexItem>OR</Divider>
              <Button
                variant="contained"
                component="a"
                onClick={() => downloadTemplateWithBenchmarkFiles()}
              >
                Benchmark files
              </Button>
            </Stack>
          </StepContent>
        </Step>
        <Step active>
          <StepLabel>Fill in the template</StepLabel>
        </Step>
        <Step active>
          <StepLabel>Upload the modified file</StepLabel>
          <StepContent>
            <Button
              variant="contained"
              component="label"
            >
              Upload
              <input
                type="file"
                accept=".xlsx"
                hidden
                onChange={handleFilesInput}
              />
            </Button>
          </StepContent>
        </Step>
      </Stepper>
      {requestsData && (
        <ClassificationTestBenchDialog
          requestsData={requestsData}
          onClose={() => setRequestsData(null)}
        />
      )}
    </Layout>
  );
};

export default BenchmarkPage;
