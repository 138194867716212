const createSheetWithSuccessRateByExpectedType = (workbook, configs, results) => {
  const resultsByConfig = configs.map((config, index) => ({
    configName: `Config ${config.id}`,
    results: results[index],
  }));

  const existingSheet = workbook.getWorksheet('Success Rate by Expected Type');
  if (existingSheet) {
    workbook.removeWorksheet(existingSheet.id);
  }

  const sheet = workbook.addWorksheet('Success Rate by Expected Type');

  sheet.columns = [
    { header: 'Expected Type', key: 'expectedType', width: 30 },
    ...resultsByConfig.map((rc) => ({ header: rc.configName, key: rc.configName, width: 15 })),
  ];

  // Make the header row bold
  sheet.getRow(1).font = { bold: true };

  // Calculate success rates by expected type for each config
  const successRatesByType = {};
  resultsByConfig.forEach(({ configName, results: configResults }) => {
    configResults.forEach((result) => {
      if (result) {
        const { expectedType, isSuccess } = result;
        if (!successRatesByType[expectedType]) {
          successRatesByType[expectedType] = {};
        }
        if (!successRatesByType[expectedType][configName]) {
          successRatesByType[expectedType][configName] = { success: 0, total: 0 };
        }
        successRatesByType[expectedType][configName].total += 1;
        if (isSuccess) {
          successRatesByType[expectedType][configName].success += 1;
        }
      }
    });
  });

  // Function to interpolate color
  const interpolateColor = (percentage) => {
    const r1 = 0xF5;
    const g1 = 0xCC;
    const b1 = 0xCC; // Start color (0%)
    const r2 = 0xD9;
    const g2 = 0xEA;
    const b2 = 0xD3; // End color (100%)

    const r = Math.round(r1 + (r2 - r1) * (percentage / 100));
    const g = Math.round(g1 + (g2 - g1) * (percentage / 100));
    const b = Math.round(b1 + (b2 - b1) * (percentage / 100));

    return {
      argb: `FF${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`,
    };
  };

  // Add data rows
  Object.entries(successRatesByType).forEach(([expectedType, configResults]) => {
    const row = sheet.addRow([expectedType]);
    resultsByConfig.forEach(({ configName }, index) => {
      const result = configResults[configName];
      const successRate = result ? (result.success / result.total) * 100 : 0;
      const cell = row.getCell(index + 2); // +2 because first column is expectedType
      cell.value = result ? `${successRate.toFixed(2)}%` : 'N/A';

      // Align cell content to the right
      cell.alignment = { horizontal: 'right' };

      if (result) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: interpolateColor(successRate),
        };
      }
    });
  });
};

export default createSheetWithSuccessRateByExpectedType;
