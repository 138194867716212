import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';

import { fileListActions as actions } from 'redux/actions';

import Layout from 'components/Layout';
import FilesTable from 'components/FilesTable';
import AdminSubNavigation from 'components/Layout/Navigation/AdminSubNavigation';

import { useDidMount, useWillUnmount } from 'hooks';

import useStyles from './BenchmarkFilesPage.styles';

const BenchmarkFilesPage = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();

  useDidMount(() => {
    dispatch(actions.resetSort());
  });

  useWillUnmount(() => {
    dispatch(actions.reset());
  });

  useEffect(() => {
    dispatch(actions.setFileTypes({ value: ['benchmark'] }));
    dispatch(actions.getFileListRequest({}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      app="admin"
      breadCrumbsContent={[
        <Typography key="3" color="text.primary">
          Benchmark files
        </Typography>,
      ]}
      subNav={<AdminSubNavigation />}
    >
      <FilesTable disableBulkProcessingActions />
    </Layout>
  );
};

export default BenchmarkFilesPage;
